import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner as StaticBanner } from "../../components/Banners/GenderAction"

import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { Partners } from "../../components/PartnersNew"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"
import { Questions } from "../../components/Questions"
import { QUESTIONS_FOR_HALVA_MEDIA } from "../../components/Questions/helpers"

const bannerTitle = `Розыгрыш до&nbsp;100% кэшбэка на&nbsp;подарки`
const bannerDescription = `
<ul>
  <li>— до 36 месяцев рассрочки</li>
  <li>— до 5000 ₽ кэшбэка  </li>
</ul>
`

const formTitle = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const subTitlePartners = (
  <>
    Совершайте покупки в любых магазинах-партнерах&nbsp;Халвы и&nbsp;участвуйте в розыгрыше кэшбэка
    до&nbsp;100% в период акции: 17.02 - 09.03
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader
        showHint
        countDownTime={countDownTime}
        hint="Активируйте акцию «Кэшбэк до&nbsp;100% на подарки любимым» с&nbsp;17.02 по 09.03, совершайте покупки и участвуйте 
        в&nbsp;розыгрыше призов"
        hintTitle={
          <>
            Участвуй в<br /> розыгрыше
          </>
        }
      />
      <StaticBanner
        variantStyle="genderMedia"
        description={bannerDescription}
        title={bannerTitle}
        orderNum="1"
      />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYearMedia"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как принять участие в&nbsp;розыгрыше"
        variant="media"
        orderNum="3"
      />
      <FormPKW
        formBtnText="Оформить карту"
        dataLayerName="shortPersonalForm"
        withTimer
        countDownTime={countDownTime}
        orderNum="4"
        title={formTitle}
        variant="btsAuto"
        isGreenApprove
        progressBar
      />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="5"
        variant="genderAction"
      />
      <Questions questionList={QUESTIONS_FOR_HALVA_MEDIA} orderNum="6" />
      <NewFooter ligal={ligal} orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/gender-action/media/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
